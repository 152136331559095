<template>
<span class="cursor-pointer info-button-color" :content="helperText" v-tippy>
  <i class="fas fa-info-circle info-button-color font-size-base pulse pulse-warning d-inline-block px-2"></i>
</span>
</template>

<script>
export default {
name: "InfoTooltip",
  props: {
    helperText: {
      default: '',
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.info-button-color {
  color: $primary-gray !important
}
</style>